import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Logo from "../assets/images/svg/logo.svg"
import "react-toastify/dist/ReactToastify.css"
import Helmet from "react-helmet"
import Arrow from "../assets/images/arrow.png"
import OleoRegular from "../assets/fonts/OleoScript-Regular.ttf"

import { setDonationComplete } from "../state/app"

const Description = styled.p`
  font-size: 16px;
  color: #8b8b8b;
  margin: 20px auto;
`

const TicketLogo = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 120px;
  margin-bottom: 20px;
`
const Flag = styled.p`
  text-align: center;

  &.small-f {
    font-size: 12px;
  }
`

const smsForm = () => css`
  @font-face {
    font-display: swap;
    font-family: "OleoScript";
    font-style: normal;
    font-weight: normal;
    src: url(${OleoRegular}) format("truetype");
  }

  font-family: "Roboto", sans-serif;
  color: #585858;
  width: 80%;
  max-width: 500px;
  margin: 0 auto;

  .t-info {
    position: relative;
  }

  h1 {
    color: #203760;
    font-size: 28px;
    text-align: center;
    font-family: "OleoScript", sans-serif;
  }

  img {
    margin: 35px auto;
    display: block;
  }

  .container {
    background-color: #f2f6ff;
    box-shadow: rgba(200, 200, 200, 0.6) 5px 5px 15px 5px;
    padding: 20px;
    margin: 0 -8px;
    font-size: 14px;
    line-height: 17px;
    font-family: Courier;

    .red {
      color: #b31022;
    }
  }
`

const Tips = styled.div`
  ${() => css`
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: rgba(200, 200, 200, 0.6) 5px 5px 15px 5px;
    margin-top: 20px;
    position: relative;
    top: -45px;
    font-size: 12px;
    color: #8b8b8b;
    line-height: 15px;

    &::after {
      content: "";
      width: 60px;
      height: 46px;
      background-image: url(${Arrow});
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      position: absolute;
      left: -40px;
      top: -85px;
      transform: rotateY(180deg) rotateZ(110deg) scale(1.5);
    }
  `}
`

const ConfirmacaoSMS = () => {
  const dispatch = useDispatch()

  const data = JSON.parse(localStorage.getItem("iurd-donation-sms-data")) || {}
  const date = new Date(data.captureDate)
  useEffect(() => {
    return () => localStorage.removeItem("iurd-donation-sms-data")
  }, [])

  useEffect(() => {
    dispatch(setDonationComplete(true))
  }, [])

  return (
    <>
      <SEO title="Cadastro SMS" />
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Helmet>
      <div css={smsForm}>
        <img src={Logo} alt="Logo da Universal" />
        <h1>
          Sua doação foi <br />
          enviada com sucesso
        </h1>
        <Description>
          Obrigado por colaborar com a sua doação! Sua ajuda tem um grande valor
          para nós.
        </Description>
        <div className="container">
          <TicketLogo src={Logo} alt="Universal" />
          <Flag>
            {data.cardBrand} <br />
            Crédito à vista <br />
          </Flag>

          <p className="t-info">
            VIA - SITE DOAÇÕES UNIVERSAL <br />
            POS: DOAÇÕES <br />
            CPF:{" "}
            {(data.userDocument || "").replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/g,
              "$1.$2.$3-$4"
            )}{" "}
            <br />
            <strong>{(data.userName || "").toUpperCase()}</strong> <br />
            <strong className="red"> AUT: {data.transactionId}</strong> <br />
            {date.toLocaleString("pt-BR")} <br />
            DOAÇÃO POR CRÉDITO <br />
            <strong>{data.value}</strong>
            <br />
          </p>

          <Flag className="small-f">
            TRANSAÇÃO AUTORIZADA ONLINE <br />
            IGREJA UNIVERSAL
          </Flag>
        </div>
        <Tips>
          Escreva esse código no verso do envelope que você vai entregar no
          altar.
        </Tips>
      </div>
    </>
  )
}

export default ConfirmacaoSMS
